import React from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import Contact from "../components/contact"
import { Row, Col } from "react-bootstrap"
import SmallBanner from "../components/small-banner"

const AboutPage = ({ data: { content } }) => {
  return (
    <>
      <Seo title="About" />
      <SmallBanner />
      <Row>
        <Col sm={{ span: 10, offset: 1 }} className="mt-5">
          <div
            className="text-left"
            dangerouslySetInnerHTML={{ __html: content.html }}
          ></div>
        </Col>
      </Row>
      <Contact />
    </>
  )
}

export const query = graphql`
  query {
    content: markdownRemark(fileAbsolutePath: { glob: "**/about.md" }) {
      html
    }
  }
`

export default AboutPage
